@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerForm {
  width:80%;
  padding:15px;
  background-color:#ffffff30!important;
  text-align:left;
}

.cardsServices {
  border-left:1rem solid #1e1e1e !important;
  width: 70% !important;;
  margin-top:50px !important;
  border-radius:25px !important;
  padding: 1rem 0;
  padding-left: 12px !important;
  background-color: #fbfbfb !important;
  cursor: pointer;
}

.cardsServices h1{
  color: #1e1e1e;
  font-weight: 800;
  font-size: 1.5rem;
  margin: 0;
}

.cardsServices p{
  color: #1e1e1e;
  font-weight: 800;
  font-size: 1.1rem;
  margin: 0;
}

.card-chat {
  width: 100%;
  max-height: 350px; /* Ajuste conforme necessário */
  padding: 1rem 2rem;
  overflow-y: auto; /* Adiciona rolagem vertical se o conteúdo exceder o max-height */
}


.chat-container {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 400px;
  margin: auto;
}

.message {
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 20px;
}

.message-user {
  background-color: #ba9cef;
  text-align: right;
  color: #f5f5f5;
  font-weight: 500;
}

.message-bot {
  background-color: #ebebeb;
  text-align: left;
}

.message-input {
  width: 100%;
  padding: 7px;
  margin: 10px 0;
  margin-right: 0.5rem;
  border-radius: 15px;
  border: 1px solid #ddd;
}

.send-button {
  background-color: #7d56c0;
  color: white;
  padding: 7px 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

/* Responsividade */
@media (max-width: 600px) {
  .chat-container {
      width: 100%;
      padding: 10px;
  }
}


.cardsProtec {
  max-width:360px!important;
  border:0.2rem solid #e1e1e1!important;
  border-top:0.3rem solid #1e1e1e!important;
  border-radius:15px!important;
  padding:1.3rem!important;
  margin: 0.8rem;
  display: flex;
  align-items: center;
  margin-top: 25px!important;
}

.contactBtn {
  background-color: #ff6900;
  border: 0.2rem solid #ff6900;
  width: 400px;
  text-align: center;
  font-size: 1.6rem;
  color: #e1e1e1;
  border-radius: 10px;
  padding: 0.2rem;
  margin-top: 40px;
  text-decoration: none!important;
}

.contactBtn:hover {
  background-color: #e1e1e1;
  color: #ff6900;
}

.headerBtn {
  background-color: #1e1e1e;
  border: 0.2rem solid #1e1e1e;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  color: #e1e1e1;
  border-radius: 10px;
  padding: 0.2rem;
  margin-top: 40px;
  text-decoration: none!important;
}

.headerBtn:hover {
  border-color: #fbfbfb;
  background-color: #fbfbfb;
  color: #1e1e1e;
}

.border-50-rounded {
  border-radius: 50%;
}

@media screen and (min-width: 451px){
  .hiddenDesktop {
    display: none;
  }
}

@media screen and (max-width: 450px){
  h1 {
    font-size: 2rem!important;
  }

  .headerLogo {
    text-align: center!important;
  }

  .headerLogo img {
    margin-top: 50px;
    max-width: 250px!important;
  }

  .headerText {
    padding-top: 50px!important;
  }

  .cardsProtec {
    margin: 0px!important;
    margin-top: 45px!important;
    padding: 20px!important;
  }

  .contactBtn {
    max-width: 80%;
  }

  .cardsHolder {
    max-width: 100%;
  }

  .headerForm {
    width: 120%!important;
  }

  .headerContainer {
    height: 1000px!important;
  }

  .hiddenMobile {
    display: none;
  }
}